export function generateStyleSheets() {
  const rules: CSSRuleList[] = [];
  let stringifiedRules: string = "";
  const sheets = document.styleSheets;
  const externalSheets: string[] = [];

  Object.keys(sheets).forEach((key) => {
    let value = sheets[key];

    if (value.href) {
      externalSheets.push(value.href);
      return;
    }

    try {
      return rules.push(value.cssRules);
    } catch (error) {
      console.log(error);
    }

    return rules;
  });

  Object.keys(rules).forEach((key) => {
    let value: CSSRuleList = rules[key];

    Object.keys(value).forEach((k) => {
      let v: CSSStyleRule = value[k];

      stringifiedRules = stringifiedRules.concat(v.cssText);
    });
  });

  return {
    styleSheet: stringifiedRules.toString(),
    externalSheets,
  };
}
