import { host } from "./config";

export async function setVariantMarkup(
  variantApiId: string,
  markup: string,
  styleString: string,
  externalStyleSheets: string[]
) {
  const query = JSON.stringify({
    operationName: "SetVariantMarkup",
    query: `mutation SetVariantMarkup($variantApiId: String!, $markup: String!, $styleString: String!, $externalStyleSheets: [String!]!){
      setVariantMarkup(input: { variantApiId: $variantApiId, markup: $markup, styleString: $styleString, externalStyleSheets: $externalStyleSheets }) {
        success
        errors
      }
    }`,
    variables: {
      variantApiId,
      markup,
      styleString,
      externalStyleSheets,
    },
  });

  const response = await fetch(host, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: query,
  });

  const responseJson = await response.json();
  return responseJson.data;
}
