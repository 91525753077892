import { host } from "./config";

export async function createImpression(impression: any) {
  let responseJson: any;
  let visitorApiIdLocal = localStorage.getItem("preferrVisitorApiId");

  // Check continuously for the visitorApiId to be set in localStorage
  var interval = setInterval(function () {
    visitorApiIdLocal = localStorage.getItem("preferrVisitorApiId");
  }, 0);

  // After 2 seconds, stop the interval and create an impression
  setTimeout(async function () {
    clearInterval(interval);

    const query = JSON.stringify({
      operationName: "CreateImpression",
      query: `mutation CreateImpression($visitorApiId: String, $impression: ImpressionAttributes!){
      createImpression(input: { visitorApiId: $visitorApiId, impression: $impression }) {
        success
        errors
        impression {
          id
          abTestVariantId
        }
      }
    }`,
      variables: {
        visitorApiId: visitorApiIdLocal,
        impression: {
          abTestVariantApiId: impression.abTestVariantApiId,
          url: document.location.href,
        },
      },
    });

    const response = await fetch(host, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: query,
    });

    responseJson = await response.json();
  }, 2000);

  if (responseJson) {
    return responseJson.data;
  }
}
