import { host } from "./config";

export async function findOrCreateVisitor(
  organizationApiKey: string,
  visitorApiId?: string
) {
  const query = JSON.stringify({
    operationName: "FindOrCreateVisitor",
    query: `mutation FindOrCreateVisitor($organizationApiKey: String!, $visitorApiId: String){
      findOrCreateVisitor(input: { organizationApiKey: $organizationApiKey, visitorApiId: $visitorApiId, }) {
        success
        errors
        visitor {
          apiId
          uid
          name
          email
        }
      }
    }`,
    variables: {
      organizationApiKey,
      visitorApiId,
    },
  });

  const response = await fetch(host, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: query,
  });

  const responseJson = await response.json();

  if (responseJson.data.findOrCreateVisitor.success) {
    localStorage.setItem(
      "preferrVisitorApiId",
      responseJson.data.findOrCreateVisitor.visitor.apiId
    );
  }

  return responseJson.data;
}
