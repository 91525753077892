import { host } from "./config";
import { findOrCreateVisitor } from "./findOrCreateVisitor";

export default class Preferr {
  static suppressWarnings() {
    localStorage.setItem('preferrSuppressWarnings', "true")
  }

  static allowWarnings() {
    localStorage.removeItem('preferrSuppressWarnings')
  }

  static async init(apiKey: string) {
    const query = JSON.stringify({
      operationName: "AuthenticatePreferr",
      query: `query AuthenticatePreferr($apiKey: String!){
          authenticatePreferr(apiKey: $apiKey) {
            success
            errors
          }
        }`,
      variables: {
        apiKey: apiKey,
      },
    });

    const response = await fetch(host, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: query,
    });

    const responseJson = await response.json();

    if (responseJson.data.authenticatePreferr.success) {
      localStorage.setItem("authenticatedPreferrApiKey", apiKey);

      const storedVisitorApiId =
        localStorage.getItem("preferrVisitorApiId") || undefined;
      await findOrCreateVisitor(apiKey, storedVisitorApiId);
    }

    if (responseJson.data.authenticatePreferr.errors.length > 0) {
      console.error(responseJson.data.authenticatePreferr.errors[0]);
      localStorage.removeItem("authenticatedPreferrApiKey");
    }

    return responseJson.data;
  }

  static async identify(
    uid: string,
    name?: string,
    email?: string,
    meta?: object
  ) {
    const storedApiKey: string | null = localStorage.getItem(
      "authenticatedPreferrApiKey"
    );
    const storedVisitorApiId: string | null = localStorage.getItem(
      "preferrVisitorApiId"
    );

    if (!storedApiKey || !storedVisitorApiId) {
      return null;
    }

    const query = JSON.stringify({
      operationName: "UpdateVisitor",
      query: `mutation UpdateVisitor($organizationApiKey: String!, $visitorApiId: String!, $visitor: VisitorAttributes!){
          updateVisitor(input: { organizationApiKey: $organizationApiKey, visitorApiId: $visitorApiId, visitor: $visitor }) {
            success
            errors
            visitor {
              apiId
              uid
              name
              email
            }
          }
        }`,
      variables: {
        organizationApiKey: storedApiKey,
        visitorApiId: storedVisitorApiId,
        visitor: {
          uid,
          name,
          email,
          meta,
        },
      },
    });

    const response = await fetch(host, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: query,
    });

    const responseJson = await response.json();

    if (
      responseJson.data &&
      responseJson.data.updateVisitor &&
      responseJson.data.updateVisitor.success
    ) {
      return responseJson.data;
    } else {
      throw `Preferr has encountered an error. ${responseJson.data}`;
    }
  }

  static async convert(abTestId: string) {
    const storedApiKey: string | null = localStorage.getItem(
      "authenticatedPreferrApiKey"
    );

    const storedVisitorApiId: string | null = localStorage.getItem(
      "preferrVisitorApiId"
    );

    const storedVariantId: string | null = localStorage.getItem(abTestId);

    if (!storedApiKey || !storedVisitorApiId || !storedVariantId) {
      return null;
    }

    const query = JSON.stringify({
      operationName: "CreateConversion",
      query: `mutation CreateConversion($visitorApiId: String, $conversion: ConversionAttributes!){
        createConversion(input: { visitorApiId: $visitorApiId, conversion: $conversion }) {
          success
          errors
          conversion {
            id
            abTestVariantId
          }
        }
      }`,
      variables: {
        visitorApiId: storedVisitorApiId,
        conversion: {
          abTestVariantApiId: storedVariantId,
        },
      },
    });

    const response = await fetch(host, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: query,
    });

    const responseJson = await response.json();
    return responseJson.data;
  }
}
