import React from "react";
import { chooseWeighted } from "../chooseWeighted";
import { createImpression } from "../createImpression";
import { setVariantMarkup } from "../setVariantMarkup";
import { generateStyleSheets } from "../generateStyleSheets";

export type TABTestProps = {
  abTestId: string;
  variantAId: string;
  variantBId: string;
  children: [React.ReactElement, React.ReactElement];
  weightA?: number;
  weightB?: number;
};

const ABTest: React.FC<TABTestProps> = ({
  abTestId,
  variantAId,
  variantBId,
  children,
  weightA = 50,
  weightB = 50,
}: TABTestProps) => {
  if (!window && !localStorage.getItem("preferrSuppressWarnings")) {
    console.warn(
      "localStorage not found. If you're using SSR with React, you'll need to find a way to delay the render of an ABTest component until localStorage is available."
    );

    return null;
  }

  if (
    !localStorage.getItem("preferrSuppressWarnings") &&
    !localStorage.getItem("authenticatedPreferrApiKey")
  ) {
    console.warn(
      "Preferr has not been initialized. Be sure to call Preferr.init() with your API key somewhere in the root of your application."
    );
  }

  const childCount = React.Children.count(children);

  if (childCount !== 2) {
    throw new Error(
      `An ABTest must have 2 and only 2 children. ${childCount} children were detected.`
    );
  }

  const childTypes = children.map((c) => c.type.toString());

  if (childTypes.includes("Symbol(react.fragment)")) {
    throw new Error(
      "An ABTest component cannot have a Fragment as a direct child"
    );
  }

  React.useLayoutEffect(() => {
    const storedVariantId = localStorage.getItem(abTestId);

    if (storedVariantId) {
      createImpression({
        abTestVariantApiId: storedVariantId,
      });

      const renderedNode = document.querySelectorAll(
        `[data-preferr-abt-id="${abTestId}"]`
      )[0];

      const { externalSheets, styleSheet } = generateStyleSheets();

      // If we can't set the data attribute, put a console warning
      if (renderedNode) {
        setVariantMarkup(
          storedVariantId,
          renderedNode.outerHTML,
          styleSheet,
          externalSheets
        );
      } else {
        setVariantMarkup(
          storedVariantId,
          "<div>Looks like we had trouble determining the HTML for this variant. For more information about how to avoid this, please see the troubleshooting section of the docs.</div>",
          styleSheet,
          externalSheets
        );
        if (!localStorage.getItem("preferrSuppressWarnings")) {
          console.warn(
            "Preferr could not set attributes for child nodes. You may need to add a rest prop and spread it in your component."
          );
        }
      }
    }
  }, []);

  if (weightA + weightB !== 100) {
    throw new Error(`An ABTest must have weights that sum to 100.`);
  }

  const kids = React.Children.map(children, (c, idx) => {
    const child = c as React.ReactElement;

    const assignWeight = idx === 0 ? weightA : weightB;

    const currentChildId = idx === 0 ? variantAId : variantBId;

    const newProps = {
      ...child.props,
      preferrweight: assignWeight,
      persistedvariantid: currentChildId,
      "data-preferr-abt-id": abTestId,
      key: idx,
    };

    const clonedChild = React.cloneElement(child, newProps);

    return clonedChild;
  });

  function renderChild() {
    let winner: React.ReactElement;
    if (localStorage.getItem(abTestId) === variantAId) {
      winner = kids[0];
    } else if (localStorage.getItem(abTestId) === variantBId) {
      winner = kids[1];
    } else {
      winner = chooseWeighted(kids, [
        kids[0].props.preferrweight,
        kids[1].props.preferrweight,
      ]);

      localStorage.setItem(abTestId, winner.props.persistedvariantid);
      // setStoredVariantId(winner.props.persistedvariantid);
    }

    return winner;
  }

  return <React.Fragment>{renderChild()}</React.Fragment>;
};

export default ABTest;
